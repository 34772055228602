import { Middleware } from '../../legacy/OneRing';

const attributesListMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('AttributeName', 'AttributeName'),
    Middleware.CreateMapping('DataType', 'DataType'),
    Middleware.CreateArrayMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AttributesList', 'GET')) {
        Middleware.RegisterMapping('AttributesList', 'GET', attributesListMapping);
    }
}

const attributesList = {
    ConfigureMiddleware
};

export default attributesList;
