import React from 'react';
import { Redirect } from 'react-router-dom';
import { Middleware } from '../legacy/OneRing';
import LoggingUtilities from './LoggingUtilities';
import APIEndPoints from '../services/api';
import { DuAuthenticationUtilities } from '../legacy/DriscollsReactUtilities';
const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;
const masterDataCalls = [
    {
        name: 'LanguageOptions',
        endpoint: APIEndPoints.LANGUAGES,
        weight: 1,
        returnFunction: (data, props) => {
            props.setLanguageOptions(data.Results);
        },
        method: 'GET'
    },
    {
        name: 'DatatypeList',
        endpoint: APIEndPoints.DATATYPE_LIST,
        weight: 1,
        returnFunction: (data, props) => {
            props.setDataTypeList(data.Results);
        },
        method: 'GET'
    },
    {
        name: 'Attributes',
        endpoint: APIEndPoints.DATA_ATTRIBUTE_LIST,
        weight: 19,
        returnFunction: (data, props) => {
            props.setAttributeOptionsList(data.Results);
        },
        method: 'GET'
    },
    {
        name: 'ValidateFileData',
        endpoint: APIEndPoints.VALIDATION_DATA_LIST,
        weight: 6,
        returnFunction: (data, props) => {
            props.setValidationData(data);
        },
        method: 'GET'
    },
    {
        name: 'DataEntityList',
        endpoint: APIEndPoints.DATA_ENTITY_LIST,
        weight: 27,
        returnFunction: (data, props) => {
            props.setEntityOptionsList(data.Results);
        },
        method: 'GET'
    },
    {
        name: 'Roles',
        endpoint: APIEndPoints.ROLES,
        weight: 2,
        returnFunction: (data, props) => {
            props.setGroups(data.OktaGroupList);
        },
        method: 'GET'
    },
    {
        name: 'Translations',
        endpoint: APIEndPoints.TRANSLATION,
        weight: 39,
        returnFunction: (translations, props) => {
            props.setTranslationRules(translations);
        },
        method: 'GET'
    }
];

const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

var lastMasterDataLoadTime = null;
var hasLoggedMasterDataWarning = false;
var hasLoggedMasterDataInfo = false;
var redirectLocationReducerFunction = () => {
    // body
};

var getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    // TODO: debug columnNamesMapping
    var apiCalls = [];
    masterDataCalls.forEach((call) => {
        var middlewareCall;
        if (call.name === 'Translations' || call.name === 'ValidateFileData') {
            middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);
            middlewareCall.props = {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            };
        } else {
            middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);
        }

        // set user language
        let decodedToken = DuAuthenticationUtilities.DecodeToken(token);
        let userSelection = localStorage.getItem('preferredLanguage');
        if (!userSelection) {
            props.setPreferredLanguage({ lan: decodedToken.lan });
        }

        middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

var checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

var redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Redirect to="/InitializeApplication/" />;
};

var registerMasterDataReducerVariables = (setMasterDataRedirectLocation, setMasterDataInitialized) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables
};

export default MasterDataUtilities;
