import { Middleware } from '@/legacy/OneRing';

const rolesMapping = [
    Middleware.CreateArrayMapping('OktaGroupList', 'OktaGroupList'),
    Middleware.CreateMapping('RoleId', 'RoleId'),
    Middleware.CreateMapping('RoleName', 'RoleName'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Roles', 'GET')) {
        Middleware.RegisterMapping('Roles', 'GET', rolesMapping);
    }
}

const roles = {
    ConfigureMiddleware
};

export default roles;
