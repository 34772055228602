import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from './data/store';
import App from './App';
import Middleware from './data/middleware';
import * as serviceWorker from './serviceWorker';
import { SERVICE_WORKER_UPDATE } from './actions/actions';

declare global {
    interface Window {
      config: any;
    }
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));

    const renderApp = () =>
        root.render(
          <Provider store={Store}>
              <App />
          </Provider>,
        );

Middleware.ConfigureAllMiddleware();

const serviceWorkerConfig = {
    onUpdate: (reg) => Store.dispatch({ type: SERVICE_WORKER_UPDATE, payload: reg })
};

serviceWorker.register(serviceWorkerConfig);
renderApp();
