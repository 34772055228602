import { Middleware } from '@/legacy/OneRing';

const validateFileDataMapping = [
  Middleware.CreateMapping('Blue', 'Blue'),
  Middleware.CreateMapping('Black', 'Black'),
  Middleware.CreateMapping('Rasp', 'Rasp'),
  Middleware.CreateMapping('Straw', 'Straw'),
  Middleware.CreateMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
  if (!Middleware.CheckMappingExists('ValidateFileData', 'GET')) {
    Middleware.RegisterMapping('ValidateFileData', 'GET', validateFileDataMapping);
  }
}

const validateFileData = {
  ConfigureMiddleware
};

export default validateFileData;
