import { Middleware } from '../../legacy/OneRing';

const locationsMapping = [
    Middleware.CreateMapping('LocationName', 'LocationName'),
    Middleware.CreateMapping('LocationCode', 'LocationCode'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('isObservable', 'isObservable'),
    Middleware.CreateMapping('TotalCount', 'TotalCount'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const filterLocationsMapping = [
    Middleware.CreateMapping('LocationName', 'LocationName'),
    Middleware.CreateMapping('LocationCode', 'LocationCode'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('isObservable', 'isObservable'),
    Middleware.CreateMapping('TotalCount', 'TotalCount'),
    Middleware.CreateArrayMapping('Records', 'Records')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Locations', 'GET')) {
        Middleware.RegisterMapping('Locations', 'GET', locationsMapping);
        Middleware.RegisterMapping('Locations', 'POST', filterLocationsMapping);
    }
}

const attributesList = {
    ConfigureMiddleware
};

export default attributesList;
