import { Middleware } from '@/legacy/OneRing';

const s3CredentialsMapping = [
    Middleware.CreateMapping('AccessKey', 'AccessKey'),
    Middleware.CreateMapping('SecretKey', 'SecretKey'),
    Middleware.CreateMapping('SessionToken', 'SessionToken')
];

const s3FileNameToMule = [
    Middleware.CreateMapping('FileName', 'FileName'),
    Middleware.CreateMapping('FileKey', 'FileKey'),
    Middleware.CreateMapping('Source', 'Source'),
    Middleware.CreateMapping('Entity', 'Entity'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('FieldCode', 'FieldCode'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('S3Credentials', 'GET')) {
        Middleware.RegisterMapping('S3Credentials', 'GET', s3CredentialsMapping);
        Middleware.RegisterMapping('S3Credentials', 'POST', s3FileNameToMule);
    }
}

const s3Credentials = {
    ConfigureMiddleware
};

export default s3Credentials;
