import React from 'react';
import DrcImage from './DrcImage';
import { withStyles } from '@material-ui/core/styles';
import { DuIconSrc } from '@/legacy/DriscollsReactUtilities';

/**
 *@ignore
 */
const styles = (theme) => ({
    root: {
        textAlign: 'center',
        '& img': {
            transform: 'scale(0.7)',
            animation: '$di-loading 1s linear infinite'
        },
        '& img:nth-of-type(2)': {
            animationDelay: '0.2s'
        },
        '& img:nth-of-type(3)': {
            animationDelay: '0.4s'
        },
        '& img:nth-of-type(4)': {
            animationDelay: '0.6s'
        }
    },
    hidden: {
        '& img': {
            visibility: 'hidden'
        }
    },
    '@keyframes di-loading': {
        '0%': { transform: 'scale(0.7)' },
        '25%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(0.7)' }
    }
});

/**
 * @description DrcLoading is a styled loading screen in the Driscolls format and is customizable
 * @class DrcLoading
 * @example
 * <DrcLoading text={'Loading all the details ...'} hidden={false} size={'Small'}/>
 * @category Loading
 * @tags loading backdrop berries
 * @property {string} text Loading text
 * @property {string} size Size of berry icons (i.e Small, Medium)
 * @property {json} style Loading text style
 * @property {boolean} hidden Hides the berry icons
 * @extends {React.Component} */
class DrcLoading extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcLoading
     */
    render() {
        const { size, text, style, classes, hidden } = this.props;

        var imgSize = size || 'Medium';

        return (
            <div className={`${classes.root} ${hidden ? classes.hidden : ''}`}>
                <span style={{ margin: '0 auto' }}>
                    <DrcImage
                        src={imgSize === 'Medium' ? DuIconSrc.Black_Medium_Png : DuIconSrc.Black_Small_Png}
                        webp={imgSize === 'Medium' ? DuIconSrc.Black_Medium_WebP : DuIconSrc.Black_Small_WebP}
                        alt="Blackberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? DuIconSrc.Blue_Medium_Png : DuIconSrc.Blue_Small_Png}
                        webp={imgSize === 'Medium' ? DuIconSrc.Blue_Medium_WebP : DuIconSrc.Blue_Small_WebP}
                        alt="Blueberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? DuIconSrc.Rasp_Medium_Png : DuIconSrc.Rasp_Small_Png}
                        webp={imgSize === 'Medium' ? DuIconSrc.Rasp_Medium_WebP : DuIconSrc.Rasp_Small_WebP}
                        alt="Raspberry"
                    />
                    <DrcImage
                        src={imgSize === 'Medium' ? DuIconSrc.Straw_Medium_Png : DuIconSrc.Straw_Small_Png}
                        webp={imgSize === 'Medium' ? DuIconSrc.Straw_Medium_WebP : DuIconSrc.Straw_Small_WebP}
                        alt="Strawberry"
                    />
                </span>
                {text ? (
                    <span
                        style={{
                            display: 'block',
                            fontSize: imgSize === 'Medium' ? '1.25rem' : '1rem',
                            ...style
                        }}
                    >
                        {text}
                    </span>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcLoading);
