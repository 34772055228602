import ApiUtilities from './ApiUtilities';
import { DuCache, DuAuthenticationUtilities } from '@/legacy/DriscollsReactUtilities';

const InitialProps = {
    showLoadingScreen: () => {},
    hideLoadingScreen: () => {},
    showErrorDialog: () => {},
    overrideRequestMapping: false,
    overrideResponseMapping: false,
    loadingMessage: 'Loading'
};

const supportedMethods = ['GET', 'PUT', 'POST', 'DELETE', 'PATCH'];

var mappings = {};
var sortableMappingKeys = {};
var storedProps = InitialProps;
var overrideToken = '';

var defaultPayloadHandler = () => {};
var isDefaultPayloadLoaded = false;
var isDefaultPayloadSet = false;
var defaultPayload = {};
/**
 * @description Function to check middleware mapping exists or not
 * @param {string} name Function name
 * @param {string} method HTTP method type
 * @return {*}
 */
var checkMiddlewareMapping = function(name, method) {
    var key = method.toLowerCase() + name.toLowerCase();

    return mappings[key];
};

/**
 * @description Function to create middleware mapping
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareMapping = function(localVariableName, externalVariableName, translateToLocalFunction, translateToExternalFunction, externalHandler) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName,
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: false,
        IsObject: false,
        ExternalArrayVariableNameToCount: '',
        IsIndex: false,
        IsSorting: false
    };
};

/**
 * @description Function to create middleware index mapping
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareIndexMapping = function(localVariableName, externalVariableName, translateToLocalFunction, translateToExternalFunction, externalHandler) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName,
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: false,
        IsObject: false,
        ExternalArrayVariableNameToCount: '',
        IsIndex: true,
        IsSorting: false
    };
};

/**
 * @description Function to create sorted middleware mapping
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareSortMapping = function(localVariableName, externalVariableName, translateToLocalFunction, translateToExternalFunction, externalHandler) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName,
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: false,
        IsObject: false,
        ExternalArrayVariableNameToCount: '',
        IsIndex: false,
        IsSorting: true
    };
};

/**
 * @description Function to create middleware mapping for array
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareArrayMapping = function(localVariableName, externalVariableName, translateToLocalFunction, translateToExternalFunction, externalHandler) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName || '',
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: true,
        IsObject: false,
        ExternalArrayVariableNameToCount: '',
        IsIndex: false,
        IsSorting: false
    };
};

/**
 * @description Function to create middleware mapping for array that has result count
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareArrayResultCountMapping = function(
    localVariableName,
    externalVariableName,
    externalArrayVariableNameToCount,
    translateToLocalFunction,
    translateToExternalFunction,
    externalHandler
) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName,
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: false,
        IsObject: false,
        ExternalArrayVariableNameToCount: externalArrayVariableNameToCount || '',
        IsIndex: false,
        IsSorting: false
    };
};

/**
 * @description Function to create middleware mapping for object
 * @param {string} localVariableName local variable name for mapping
 * @param {string} externalVariableName variable name from api response (external)
 * @param {function} translateToLocalFunction function to change localVariableName
 * @param {function} translateToExternalFunction function to change externalVariableName
 * @return {*}
 */
var createMiddlewareObjectMapping = function(localVariableName, externalVariableName, translateToLocalFunction, translateToExternalFunction, externalHandler) {
    return {
        LocalVariableName: localVariableName,
        ExternalVariableName: externalVariableName,
        TranslateToLocalFunction: translateToLocalFunction || ((data) => data),
        TranslateToExternalFunction: translateToExternalFunction || ((data) => data),
        ExternalHandler: externalHandler || null,
        IsArray: false,
        IsObject: true,
        ExternalArrayVariableNameToCount: '',
        IsIndex: false,
        IsSorting: false
    };
};

/**
 * @description Function to register middleware mapping
 * @param {string} name Function name
 * @param {string} method HTTP function method
 * @param {array} mapping Array of mappings
 * @param {boolean} allowSorting true if sorting needs to be done
 * @return {*}
 */
var registerMiddlewareMapping = function(name, method, mapping, allowSorting) {
    var key = method.toLowerCase() + name.toLowerCase();

    if (mappings[key]) {
        console.error('Middleware: Mapping already exists.');
        return;
    }

    mappings[key] = mapping;
    sortableMappingKeys[key] = allowSorting || false;
};

/**
 * @description Function to set props
 * @param {function} openLoadingScreenAction function to show loading screen
 * @param {function} closeLoadingScreenAction function to close loading screen
 * @param {function} showErrorDialogAction function to show error dialog
 * @param {boolean} useMappings true to use mapping
 * @param {string} loadingMessage message to be displayed on loader
 * @return {*}
 */
var setPropsObj = function(openLoadingScreenAction, closeLoadingScreenAction, showErrorDialogAction, useMappings = true, loadingMessage = 'Loading') {
    storedProps = {
        ...storedProps,
        showLoadingScreen: openLoadingScreenAction,
        hideLoadingScreen: closeLoadingScreenAction,
        showErrorDialog: showErrorDialogAction,
        overrideRequestMapping: !useMappings,
        overrideResponseMapping: !useMappings,
        loadingMessage: loadingMessage
    };

    return storedProps;
};

/**
 * @description function to process data of api response as per mapping
 * @param {string} key key for mapping
 * @param {array} data array of data from API/fake-data
 * @param {*} allowEmptyValues if true them data will show empty values too
 * @return {*}
 */
var processToLocal = function(key, data, allowEmptyValues) {
    if (!mappings[key]) {
        console.error('Middleware: Mapping does not exist. Cannot proceed with API call.');
        throw new Error('no mapping exists');
    }

    //go through the mapping for each item
    var mapping = mappings[key];
    var returnObj = {};
    var arrayNameMaps = [];
    var objectNameMaps = [];

    data = data || [];

    mapping.forEach(function(map) {
        if (map.IsArray) {
            arrayNameMaps.push(map);
        }
    });

    mapping.forEach(function(map) {
        if (map.IsObject) {
            objectNameMaps.push(map);
        }
    });

    //Go through all arrays in data
    arrayNameMaps.forEach(function(arrayNameMap) {
        var array = (arrayNameMap.ExternalVariableName === '' ? data : data[arrayNameMap.ExternalVariableName]) || [];

        if (!Array.isArray(array)) {
            return;
        }

        if (!returnObj[arrayNameMap.LocalVariableName]) {
            returnObj[arrayNameMap.LocalVariableName] = [];
        }

        array.forEach(function(row) {
            var returnRow = {};

            mapping.forEach(function(map) {
                var externalData = null;
                //Find the external function to call to get the external data
                if (map.ExternalHandler) {
                    externalData = map.ExternalHandler(row);
                } else {
                    //If that doesn't exist get the data from the external variable name
                    externalData = row[map.ExternalVariableName];
                }

                var localData = map.TranslateToLocalFunction ? map.TranslateToLocalFunction(externalData) : externalData;

                if (allowEmptyValues || localData || typeof localData === 'number' || typeof localData === 'boolean') {
                    returnRow[map.LocalVariableName] = localData;
                }
            });

            returnObj[arrayNameMap.LocalVariableName].push(returnRow);
        });
    });

    //Go through all objects in data
    objectNameMaps.forEach(function(objNameMap) {
        var obj = data[objNameMap.ExternalVariableName] || {};
        var returnSecondaryObj = {};

        mapping.forEach(function(map) {
            var field = obj[map.ExternalVariableName];
            var externalData = null;

            //Find the external function to call to get the external data
            if (map.ExternalHandler) {
                externalData = map.ExternalHandler(field);
            } else {
                //If that doesn't exist get the data from the external variable name
                externalData = field;
            }

            var localData = map.TranslateToLocalFunction ? map.TranslateToLocalFunction(externalData) : externalData;

            if (localData || typeof localData === 'number') {
                returnSecondaryObj[map.LocalVariableName] = localData;
            }
        });

        if (!returnObj[objNameMap.LocalVariableName]) {
            returnObj[objNameMap.LocalVariableName] = {};
        }

        returnObj[objNameMap.LocalVariableName] = returnSecondaryObj;
    });

    //Go through all variables at root level in data
    mapping.forEach(function(map) {
        if (map.IsArray || map.IsObject) {
            return;
        }

        var externalData = null;

        //Find the external function to call to get the external data
        if (map.ExternalHandler) {
            externalData = map.ExternalHandler(data);
        } else {
            //If that doesn't exist get the data from the external variable name
            externalData = data[map.ExternalVariableName];
        }

        var localData = map.TranslateToLocalFunction ? map.TranslateToLocalFunction(externalData) : externalData;

        if (localData) {
            returnObj[map.LocalVariableName] = localData;
        }
    });

    return returnObj;
};

/**
 * @description function to process data for api calls as per mapping
 * @param {*} key key for mapping
 * @param {*} data  array of data for api
 * @return {*}
 */
var processToExternal = function(key, data) {
    if (!mappings[key]) {
        console.error('Middleware: Mapping does not exist. Cannot proceed with API call.');
        throw new Error('no mapping exists');
    }

    //go through the mapping for each item
    var mapping = mappings[key];
    var returnObj = {};
    var arrayNameMaps = [];
    var objectNameMaps = [];

    data = data || [];

    mapping.forEach(function(map) {
        if (map.IsArray) {
            arrayNameMaps.push(map);
        }

        if (map.IsObject) {
            objectNameMaps.push(map);
        }
    });

    //Go through all arrays in data
    arrayNameMaps.forEach(function(arrayNameMap) {
        (data[arrayNameMap.LocalVariableName] || []).forEach(function(row) {
            var returnRow = {};

            mapping.forEach(function(map) {
                var localData = null;

                localData = row[map.LocalVariableName];

                var externalData = map.TranslateToExternalFunction ? map.TranslateToExternalFunction(localData) : localData;

                if (externalData) {
                    returnRow[map.ExternalVariableName] = externalData;
                }
            });

            if (!returnObj[arrayNameMap.ExternalVariableName]) {
                returnObj[arrayNameMap.ExternalVariableName] = [];
            }

            returnObj[arrayNameMap.ExternalVariableName].push(returnRow);
        });
    });

    //Go through all variables at root level in data
    mapping.forEach(function(map) {
        if (map.IsArray) {
            return;
        }

        var localData = null;

        //Find the external function to call to get the external data
        if (map.LocalHandler) {
            localData = map.LocalHandler(data);
        } else {
            //If that doesn't exist get the data from the external variable name
            localData = data[map.LocalVariableName];
        }

        var externalData = map.TranslateToExternalFunction ? map.TranslateToExternalFunction(localData) : localData;

        if (externalData != null && (typeof externalData === 'boolean' || externalData)) {
            returnObj[map.ExternalVariableName] = externalData;
        }
    });
    return returnObj;
};

/**
 * Converts the Arguments data in each variable to the external equivalent
 *
 * @param {string} key mapping key
 * @param {Object} args arguments
 */
var convertArgsToExternal = function(key, args) {
    if (!mappings[key]) {
        console.error('Middleware: Mapping does not exist. Cannot proceed with API call.');
        throw new Error('no mapping exists');
    }

    var mapping = mappings[key];
    var returnObj = {};

    args = args || {};

    Object.entries(args).forEach((param) => {
        var variableName = param[0];
        var variableData = param[1];

        var map = mapping.find((map) => map.LocalVariableName === variableData);

        returnObj[variableName] = !!map ? map.ExternalVariableName : variableData;
    });

    return returnObj;
};

/**
 * @description function to make RAW API calls
 * @param {string} token okta token
 * @param {string} url api endpoint
 * @param {string} method HTTP method
 * @param {object} requestObj request payload
 * @param {object} headerObj request header
 * @return {*}
 */
var sendRawApiCall = async function(token, url, method, requestObj, headerObj) {
    return sendApiCall('', token, url, method || 'GET', requestObj, { overrideRequestMapping: true, overrideResponseMapping: true }, {}, 0, headerObj);
};

/**
 * @description function to make RAW API calls without loading and error messages
 * @param {string} token okta token
 * @param {string} url api endpoint
 * @param {string} method HTTP method
 * @param {object} requestObj request payload
 * @param {object} headerObj request header
 * @return {*}
 */
var sendRawApiCallNoDialogs = async function(token, url, method, requestObj, headerObj) {
    return sendApiCall(
        '',
        token,
        url,
        method || 'GET',
        requestObj,
        {
            showLoadingScreen: () => {},
            hideLoadingScreen: () => {},
            showErrorDialog: () => {},
            overrideRequestMapping: true,
            overrideResponseMapping: true
        },
        {},
        0,
        headerObj
    );
};

/**
 * @description Function to make api calls
 * @param {string} name API mapping name
 * @param {string} token OKta token
 * @param {string} url API endpoint
 * @param {string} method HTTPS method
 * @param {object} requestObj request payload
 * @param {object} props additional properties
 * @param {*} args arguments
 * @param {number} cacheLifespanInMinutes caching time for api
 * @param {object} headerObj request header
 * @return {*}
 */
var sendApiCall = async function(name, token, url, method, requestObj, props, args, cacheLifespanInMinutes = 0, headerObj = null) {
    return new Promise(async function(resolve, reject) {
        if (!method) {
            method = 'GET';
        }

        if (!supportedMethods.includes(method.toUpperCase())) {
            console.error('Middleware: Method ' + method + ' is not supported by the OneRing. Forge a new method to use it.');
            console.log('Middleware: Method ' + method + ' is not supported by the OneRing. Forge a new method to use it.');
        }

        var key = method.toLowerCase() + name.toLowerCase();
        var cacheKey = '';
        var combinedProps = { ...storedProps, ...(props || {}) };

        if (!combinedProps.overrideRequestMapping && !mappings[key]) {
            console.error('Middleware: Mapping does not exist. Cannot proceed with API call.');
            reject(new Error('no mapping exists'));
            return;
        }

        //Get from Cache if valid and allowed
        if (cacheLifespanInMinutes > 0) {
            cacheKey = key + url.toLowerCase();

            var data = await DuCache.Get(cacheKey, cacheLifespanInMinutes * 60000, false);

            if (data) {
                resolve(data);
                return;
            }
        }

        if (!isDefaultPayloadLoaded) {
            var newPayload = defaultPayloadHandler(token);

            if (newPayload !== null) {
                if (method.toUpperCase() === 'GET') {
                    defaultPayload = null;
                } else {
                    defaultPayload = newPayload;
                }
                isDefaultPayloadSet = true;
            }

            isDefaultPayloadLoaded = true;
        }

        var request = combinedProps.overrideRequestMapping ? requestObj : processToExternal(key, requestObj || {});

        var convertedArgs = combinedProps.overrideRequestMapping ? args : convertArgsToExternal(key, args);

        var result = await ApiUtilities.HandleAPICall(
            name.toLowerCase(),
            overrideToken && overrideToken.length > 0 ? overrideToken : DuAuthenticationUtilities.GetTokenValue(token),
            url,
            method,
            request,
            combinedProps,
            mappings[key],
            convertedArgs,
            isDefaultPayloadSet ? defaultPayload : null,
            headerObj
        )
            .then(async (data) => {
                return new Promise(async function(resolve, reject) {
                    var isFakeData = ApiUtilities.CheckRegisteredFunctions(name.toLowerCase());
                    var processedData = combinedProps.overrideResponseMapping
                        ? isFakeData
                            ? data
                            : data.data
                        : processToLocal(key, isFakeData ? data : data.data, combinedProps.allowEmptyValues);

                    if (method === 'GET' && sortableMappingKeys[key]) {
                        var sortColumnName = '';
                        var arrayColumnName = '';

                        var map = mappings[key];
                        map.forEach((m) => {
                            if (m.IsArray) {
                                arrayColumnName = m.LocalVariableName;
                            }

                            if (m.IsSorting) {
                                sortColumnName = m.LocalVariableName;
                            }
                        });

                        if (arrayColumnName.length > 0 && sortColumnName.length > 0 && processedData[arrayColumnName] !== undefined) {
                            processedData[arrayColumnName] = processedData[arrayColumnName].sort((a, b) => {
                                if (typeof a[sortColumnName] === 'number') {
                                    return a[sortColumnName] - b[sortColumnName];
                                }

                                var aHasText = typeof a[sortColumnName] === 'string' && a[sortColumnName].length > 0;
                                var bHasText = typeof b[sortColumnName] === 'string' && b[sortColumnName].length > 0;

                                if (!aHasText) {
                                    return bHasText ? 1 : 0;
                                } else if (!bHasText) {
                                    return aHasText ? -1 : 0;
                                }

                                return a[sortColumnName].localeCompare(b[sortColumnName]);
                            });
                        }
                    }

                    if (cacheKey.length > 0) {
                        await DuCache.Save(cacheKey, processedData, false);
                    }

                    resolve(processedData);
                });
            })
            .catch((error) => {
                reject(error);
            });

        resolve(result);
    });
};

/**
 * @description Function to make return arguments required for sendApiCall
 * @param {string} name API mapping name
 * @param {string} token OKta token
 * @param {string} url API endpoint
 * @param {string} method HTTPS method
 * @param {object} requestObj request payload
 * @param {object} props additional properties
 * @param {*} args arguments
 * @param {number} cacheLifespanInMinutes caching time for api
 * @param {object} headerObj request header
 * @return {*}
 */
var createSendCall = function(name, token, url, method, requestObj, props, args, cacheLifespanInMinutes, headerObj) {
    return {
        name,
        token,
        url,
        method,
        requestObj,
        props,
        args,
        cacheLifespanInMinutes,
        headerObj
    };
};

/**
 * Function to add callback methods to sendCall
 * @param {function} sendCall function to make api call
 * @param {number} weight weight of api call
 * @param {function} returnFunction success callback function
 * @param {function} errorFunction error callback function
 * @return {*}
 */
var attachReturnFunction = function(sendCall, weight, returnFunction, errorFunction) {
    if (!returnFunction || typeof returnFunction !== 'function') {
        console.error('Cannot attach to Send call, no function provided!');
        return sendCall;
    }

    sendCall.weight = weight || 200;
    sendCall.returnFunction = returnFunction;
    sendCall.errorFunction =
        errorFunction ||
        ((error) => {
            console.error(error);
            throw error;
        });

    return sendCall;
};

/**
 * @description function to add delay
 * @param {number} msec time in milli seconds
 * @return {*}
 */
var sleep = function(msec) {
    return new Promise((resolve) => setTimeout(resolve, msec));
};

/**
 * @description function to make api calls with return function
 * @param {string} name mapping name
 * @param {object} callToMake api parameters
 * @param {function} statusUpdateFunction callback function
 */
var sendCallWithReturns = async function(name, callToMake, statusUpdateFunction) {
    await Middleware.Send(
        callToMake.name,
        callToMake.token,
        callToMake.url,
        callToMake.method,
        callToMake.requestObj,
        { ...InitialProps, ...callToMake.props }, //By default we don't want a loading screen for multiple calls, but we allow this to be overridden
        callToMake.args,
        callToMake.cacheLifespanInMinutes,
        callToMake.headerObj
    )
        .then((data) => {
            sendMultipleStatus[name].successCount++;
            statusUpdateFunction(
                sendMultipleStatus[name].errorCount > 0 ? 'Having Problems Loading Data' : 'Loading Data From Server',
                sendMultipleStatus[name].successCount,
                sendMultipleStatus[name].errorCount,
                sendMultipleStatus[name].total
            );

            try {
                callToMake.returnFunction(data);
            } catch (error) {
                console.error('API Return Function Error: ' + callToMake.name + ' ' + callToMake.url);
            }
        })
        .catch((error) => {
            sendMultipleStatus[name].errorCount++;
            statusUpdateFunction('Having Problems Loading Data', sendMultipleStatus[name].successCount, sendMultipleStatus[name].errorCount, sendMultipleStatus[name].total);

            try {
                callToMake.errorFunction(error);
            } catch (error) {
                console.error('API Return Function Error: ' + callToMake.name + ' ' + callToMake.url);
            }
        });
};

var sendMultipleStatus = [];
/**
 * @description function to make multiple api calls with return function
 * @param {string} name mapping name
 * @param {array} sendCalls api requests array
 * @param {function} statusUpdateFunction callback function
 * @return {*}
 */
var sendMultiple = async function(name, sendCalls, statusUpdateFunction) {
    return new Promise(async function(resolve, reject) {
        //TODO: If the status object below is set already we should cancel all pending API calls, otherwise bad things happen. This is an edge case.

        sendMultipleStatus[name] = { successCount: 0, errorCount: 0, total: sendCalls.length };

        //Sort largest to smallest
        var callsSortedLargestToSmallest = sendCalls.sort((callA, callB) => {
            return callB.weight - callA.weight;
        });

        var callsSorted = [];
        var count = sendMultipleStatus[name].total;
        var arrIndex = 0;

        //Sort Largest, then smallest, then second largest, etc (this is to balance the loading progress bar in the ui)
        for (var i = 0, j = count - 1; i <= count / 2 || j > count / 2; i++, j--) {
            if (arrIndex < count) {
                callsSorted[arrIndex] = callsSortedLargestToSmallest[i];
                arrIndex++;
            }

            if (arrIndex < count) {
                callsSorted[arrIndex] = callsSortedLargestToSmallest[j];
                arrIndex++;
            }
        }

        //While apis are not called yet keep calling more
        statusUpdateFunction('Loading Data From Server', 0, 0, count);
        var currentIndexRunning = 0;

        while (sendMultipleStatus[name].successCount + sendMultipleStatus[name].errorCount < count) {
            //See what we can run
            if (currentIndexRunning - (sendMultipleStatus[name].successCount + sendMultipleStatus[name].errorCount) < 5 && currentIndexRunning < count) {
                //lets run the next one
                var callToMake = callsSorted[currentIndexRunning];
                currentIndexRunning++;

                sendCallWithReturns(name, callToMake, statusUpdateFunction);
            }

            await Middleware.Sleep(50);
        }

        statusUpdateFunction(
            sendMultipleStatus[name].errorCount > 0 ? 'We Had Problems Loading Data' : 'Data Loaded Successful',
            sendMultipleStatus[name].successCount,
            sendMultipleStatus[name].errorCount,
            sendMultipleStatus[name].total
        );

        if (sendMultipleStatus[name].successCount === count) {
            resolve('All good in the hood!');
        } else {
            reject(`${sendMultipleStatus[name].errorCount} out of ${count} apis failed`);
        }
    });
};

/**
 * @description function to override token
 * @param {string} token okta token
 */
var registerOverrideToken = function(token) {
    overrideToken = DuAuthenticationUtilities.GetTokenValue(token) || '';
};

/**
 *  @description function to override payload handler function
 * @param {function} defaultPayloadFunction
 */
var registerDefaultPayloadHandler = function(defaultPayloadFunction) {
    defaultPayloadHandler = defaultPayloadFunction;
};

const Middleware = {
    CheckMappingExists: checkMiddlewareMapping,
    CreateMapping: createMiddlewareMapping,
    CreateIndexMapping: createMiddlewareIndexMapping,
    CreateSortMapping: createMiddlewareSortMapping,
    CreateArrayMapping: createMiddlewareArrayMapping,
    CreateArrayResultCountMapping: createMiddlewareArrayResultCountMapping,
    CreateObjectMapping: createMiddlewareObjectMapping,
    RegisterMapping: registerMiddlewareMapping,
    SetProps: setPropsObj,
    EmptyProps: InitialProps,
    Send: sendApiCall,
    SendRaw: sendRawApiCall,
    SendRawNoDialogs: sendRawApiCallNoDialogs,
    SendMultiple: sendMultiple,
    CreateSendCall: createSendCall,
    AttachReturnFunction: attachReturnFunction,
    RegisterFakeDataHandler: ApiUtilities.RegisterFunctions,
    CreateHandler: ApiUtilities.CreateHandler,
    CheckRegisteredFakeDataHandlers: ApiUtilities.CheckRegisteredFunctions,
    Filter: ApiUtilities.Filter,
    FilterToOne: ApiUtilities.FilterToOne,
    NoOp: ApiUtilities.NoOp,
    UpdateOne: ApiUtilities.UpdateOne,
    Mappings: mappings,
    Sleep: sleep,
    RegisterOverrideToken: registerOverrideToken,
    RegisterDefaultPayloadHandler: registerDefaultPayloadHandler
};

export default Middleware;
