import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { setPreferredLanguage } from '../actions/translationActions';
import { connect } from 'react-redux';
import BerryToggle from './BerryToggle';
import { setBerry } from '../actions/MasterActions';
import { DrcLanguageHeaderButton } from '../legacy/DriscollsReactComponents';

var styles = (theme) => ({
    role: {
        float: 'right',
        fontSize: 16,
        margin: 8,
        color: theme.palette.primary.light,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.primary.contrastText
        }
    },
    headerButtons: {
        float: 'right',
        marginTop: '-45px',
        borderRadius: '20px',
        padding: '6px',
        minWidth: 0,
        margin: 4,
        marginRight: '12px',
        '& svg': {
            fontSize: 24
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 500,
            width: '100px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    },
    langButtons: {
        width: '6rem !important',
        float: 'none !important',
        margin: '4px auto !important'
    },
    languageToggleBtn: {
        float: 'right',
        marginTop: '-47px',
        marginRight: '12px'
    }
});

class HeaderButtons extends React.Component {
    state = {
        isLoggedIn: false,
        loggedInAs: '',
        anchorEl: null
    };

    componentDidUpdate() {
        this.getGroup();
    }

    changeLanguage = () => {
        this.handleLanguageClose();
    };

    getGroup = async () => {
        let token = await this.props.oktaAuth.getAccessToken();

        if (!token || token.length <= 0) {
            if (this.state.isLoggedIn) {
                this.setState({ loggedInAs: '', isLoggedIn: false });
            }

            return;
        }

        if (!this.state.isLoggedIn) {
            this.setState({ loggedInAs: '', isLoggedIn: true });
        }
    };

    setLanguage = (language) => {
        this.props.setPreferredLanguage({ lan: language });
    };

    setBerry = (berry) => {
        this.props.setBerry(berry);
        localStorage.setItem('selectedBerry', berry);
    };

    render() {
        const { berry, classes } = this.props;

        return (
            <React.Fragment>
                {this.state.isLoggedIn ? (
                    <React.Fragment>
                        <div className={classes.languageToggleBtn}>
                            <DrcLanguageHeaderButton />
                        </div>

                        {window.location.pathname.indexOf('MasterData') > -1 && <BerryToggle berry={berry} setBerry={this.setBerry} />}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        berry: state.masterReducer.berry
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPreferredLanguage: (lang) => dispatch(setPreferredLanguage(lang)),
    setBerry: (berry) => dispatch(setBerry(berry))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(HeaderButtons))));
