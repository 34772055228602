import { Middleware } from '@/legacy/OneRing';

const bulkUpdateMapping = [
    Middleware.CreateArrayMapping('Plots', 'Plots'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('DataType', 'DataType'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('BulkUpdate', 'GET')) {
        Middleware.RegisterMapping('BulkUpdate', 'GET', bulkUpdateMapping);
        Middleware.RegisterMapping('BulkUpdate', 'POST', bulkUpdateMapping);
    }
}

const bulkList = {
    ConfigureMiddleware
};

export default bulkList;
