import { Middleware } from '@/legacy/OneRing';

const userEntityListMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('EntityName', 'EntityName'),
    Middleware.CreateArrayMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserEntityList', 'GET')) {
        Middleware.RegisterMapping('UserEntityList', 'GET', userEntityListMapping);
    }
}

const userEntityList = {
    ConfigureMiddleware
};

export default userEntityList;
