import React from 'react';
import { DuIconSrc } from '@/legacy/DriscollsReactUtilities';

/** @type {*} */
const icons = {
    Black_Small: <img src={DuIconSrc.Black_Small_Png} alt="Blackberry" />,
    Blue_Small: <img src={DuIconSrc.Blue_Small_Png} alt="Blueberry" />,
    Rasp_Small: <img src={DuIconSrc.Rasp_Small_Png} alt="Raspberry" />,
    Straw_Small: <img src={DuIconSrc.Straw_Small_Png} alt="Strawberry" />,
    Black_Medium: <img src={DuIconSrc.Black_Medium_Png} alt="Blackberry" />,
    Blue_Medium: <img src={DuIconSrc.Blue_Medium_Png} alt="Blueberry" />,
    Rasp_Medium: <img src={DuIconSrc.Rasp_Medium_Png} alt="Raspberry" />,
    Straw_Medium: <img src={DuIconSrc.Straw_Medium_Png} alt="Strawberry" />
};

/**
 *
 *
 * @description GetSmallBerryIcon return the berry icons for their respective codes and size
 * @param {*} berryType Code of the berry i.e 1,"1" or "BLACK" for blackBerry
 * @example icons.GetSmallBerryIcon('BLACK'); icons.GetBerryIcon(1); icons.GetBerryIcon('1')
 * @return {*}
 */
icons.GetSmallBerryIcon = function(berryType) {
    var berryIcon = null;

    switch (berryType) {
        case 1:
        case '1':
        case 'BLACK':
            berryIcon = icons.Black_Small;
            break;
        case 2:
        case '2':
        case 'BLUE':
            berryIcon = icons.Blue_Small;
            break;
        case 3:
        case '3':
        case 'RASP':
            berryIcon = icons.Rasp_Small;
            break;
        case 4:
        case '4':
        case 'STRAW':
            berryIcon = icons.Straw_Small;
            break;
        default:
            break;
    }

    return berryIcon;
};

/**
 * @description GetBerryIcon return the berry icons for their respective codes and size
 * @example icons.GetBerryIcon('BLACK', false); icons.GetBerryIcon(1, true)
 * @param {string} berryType BLACK, BLUE, RASP, STRAW or 1, 2, 3, 4
 * @param {boolean} isSmall If true return small icon else medium icon
 * @return {*}
 */
icons.GetBerryIcon = function(berryType, isSmall = true) {
    var berryIcon = null;

    switch (berryType) {
        case 1:
        case '1':
        case 'BLACK':
            berryIcon = isSmall ? icons.Black_Small : icons.Black_Medium;
            break;
        case 2:
        case '2':
        case 'BLUE':
            berryIcon = isSmall ? icons.Blue_Small : icons.Blue_Medium;
            break;
        case 3:
        case '3':
        case 'RASP':
            berryIcon = isSmall ? icons.Rasp_Small : icons.Rasp_Medium;
            break;
        case 4:
        case '4':
        case 'STRAW':
            berryIcon = isSmall ? icons.Straw_Small : icons.Straw_Medium;
            break;
        default:
            break;
    }

    return berryIcon;
};

export default icons;
