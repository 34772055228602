import { Middleware } from '@/legacy/OneRing';

const plotFactorsListMapping = [
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PlotFactorsData', 'GET')) {
        Middleware.RegisterMapping('PlotFactorsData', 'GET', plotFactorsListMapping);
    }
}

const plotFactorsMapping = {
    ConfigureMiddleware
};

export default plotFactorsMapping;
