import { Middleware } from '@/legacy/OneRing';

const ranchListMapping = [Middleware.CreateArrayMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Ranches', 'GET')) {
        Middleware.RegisterMapping('Ranches', 'GET', ranchListMapping);
    }
}

const ranchMapping = {
    ConfigureMiddleware
};

export default ranchMapping;
