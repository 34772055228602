import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { withLocalize } from 'react-localize-redux';
import OktaSignIn from '@okta/okta-signin-widget';
// import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
/**
 * @property {object} styles style for the DrcOktaWidget
 */
const styles = (theme) => ({
    widget: {
        '& #okta-sign-in.auth-container h2, & .number-challenge-view': {
            display: 'none'
        },
        '& #okta-sign-in .o-form .o-form-input .o-form-control': {
            borderRadius: '6px'
        },
        '& #okta-sign-in.auth-container #okta-signin-submit': {
            backgroundColor: theme.light.accent.primary,
            background: theme.light.accent.primary,
            border: 'none',
            boxShadow: '0px 4px 10px 0px ' + theme.light.header.shadow
        },
        '& #okta-sign-in.auth-container #okta-signin-submit:hover': {
            backgroundColor: theme.light.accent.brightness(-10),
            background: theme.light.accent.brightness(-10)
        },
        '& #okta-sign-in.auth-container .okta-form-input-field.focused-input': {
            borderColor: theme.light.accent.primary
        },
        '& #okta-sign-in .focused-input': {
            boxShadow: 'none'
        },
        '& #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:hover, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:focus, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:active':
            {
                '-webkit-box-shadow': '0 0 0 60px #fafafa inset !important',
                backgroundColor: '#fafafa !important',
                backgroundClip: 'content-box !important'
            },
        [theme.darkTheme]: {
            '& #okta-sign-in.auth-container #okta-signin-submit': {
                backgroundColor: theme.dark.accent.primary,
                background: theme.dark.accent.primary,
                boxShadow: '0px 4px 10px 0px ' + theme.dark.header.shadow
            },
            '& #okta-sign-in.auth-container #okta-signin-submit:hover': {
                backgroundColor: theme.dark.accent.brightness(-10),
                background: theme.dark.accent.brightness(-10)
            },
            '& #okta-sign-in.auth-container .okta-form-input-field.focused-input': {
                borderColor: theme.dark.accent.primary
            },
            '& #okta-sign-in.auth-container.main-container': {
                backgroundColor: theme.dark.primary,
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in.auth-container .okta-form-input-field': {
                backgroundColor: theme.dark.primary + ' !important'
            },
            '& #okta-sign-in .o-form label': {
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in.auth-container .okta-form-input-field input': {
                color: theme.dark.text.secondary,
                '-webkit-text-fill-color': theme.dark.text.secondary,
                '-webkit-box-shadow': '0 0 0px 1000px #000 inset'
            },
            '& #okta-sign-in.auth-container .link:link': {
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in .infobox': {
                backgroundColor: theme.dark.primary,
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:hover, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:focus, & #okta-sign-in.auth-container .okta-form-input-field input:-webkit-autofill:active':
                {
                    color: theme.dark.text.secondary,
                    '-webkit-text-fill-color': theme.dark.text.secondary,
                    '-webkit-box-shadow': '0 0 0px 1000px #000 inset !important',
                    backgroundColor: theme.dark.primary + ' !important',
                    backgroundClip: 'content-box !important'
                }
        }
    }
});

/**
 * @description The DrcOktaWidget is an authentication widget & uses Okta Sign-In authentication
 * @author stephen.weessies
 * @property {Object} className styling object for the widget
 * @property {Object} classes specific styling classes
 * @class DrcOktaWidget
 * @example
 *   <DrcOktaWidget className={this.props.className}/>
 * @donotprint true
 * @category Security
 * @tags security okta login permission username password
 * @extends {React.Component} */
class DrcOktaWidget extends Component {
    /**
     * @description This is the method that is called when the props or state updated
     * @author stephen.weessies
     * @return {*}
     * @memberof DrcOktaWidget
     */
    componentDidUpdate() {
        try {
            if (this.widget) {
                this.widget.remove();
            }

            this.widget = new OktaSignIn({
                baseUrl: this.props.baseUrl,
                language: this.props.activeLanguage ? this.props.activeLanguage.code : 'en', //Default to English
                ...this.props.config
            });

            this.widget.renderEl({ el: this.node }, this.props.onSuccess, this.props.onError);
        } catch (error) {}
    }
    /**
     * @description  This is the method that is called when the component mounts
     * @author stephen.weessies
     * @return {*}
     * @memberof DrcOktaWidget
     */
    componentWillUnmount() {
        try {
            this.widget.remove();
        } catch (error) {}
    }
    /**
     *
     *
     * @return {*}
     * @memberof DrcOktaWidget
     */
    render() {
        var { classes, className } = this.props;

        return (
            <div className={`${classes.widget} ${className}`}>
                <div ref={(node) => (this.node = node)} />
            </div>
        );
    }
}

// export default withLocalize(withStyles(styles)(DrcOktaWidget));
export default withStyles(styles)(DrcOktaWidget);
