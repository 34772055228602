import { Middleware } from '@/legacy/OneRing';

const datatypeListMapping = [
    Middleware.CreateMapping('DatatypeId', 'DataTypeId'),
    Middleware.CreateMapping('Datatype', 'DataType'),
    Middleware.CreateArrayMapping('Results', 'Results'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('DatatypeList', 'GET')) {
        Middleware.RegisterMapping('DatatypeList', 'GET', datatypeListMapping);
    }
}

const datatypeList = {
    ConfigureMiddleware
};

export default datatypeList;
