import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Translate } from 'react-localize-redux';
import DrcButton from './DrcButton';
import DrcIconButton from './DrcIconButton';
import DrcDialog from './DrcDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { isIOS, isSafari, isEdge, isChrome, isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    btn: {
        position: 'fixed',
        bottom: 0,
        background: 'transparent !important',
        borderColor: 'transparent !important',
        margin: 0
    }
});

function DrcPwaInstallHelper() {
    const classes = useStyles();
    const [pwaDialogOpen, setPwsDialogOpen] = useState(false);

    const getInfo = () => {
        if (isIOS || isSafari) {
            return (
                <div>
                    <p>
                        Apple1
                    </p>
                    Apple2
                </div>
            );
        } else if (isEdge) {
            return (
                <div>
                    Edge
                </div>
            );
        } else if (isChrome) {
            if (isMobile) {
                return (
                    <div>
                        Chrome Mobile
                    </div>
                );
            }
            return (
                <div>
                    Chrome
                </div>
            );
        }

        return (
            <div>
                Sorry
            </div>
        );
    };

    return (
        <>
            <DrcIconButton
                className={classes.btn}
                onClick={() => {
                    setPwsDialogOpen(true);
                }}
            >
                <GetAppIcon />
            </DrcIconButton>
            <DrcDialog
                title='title'
                open={pwaDialogOpen}
                onClose={() => {
                    setPwsDialogOpen(false);
                }}
                buttons={
                    <>
                        <DrcButton
                            onClick={() => {
                                setPwsDialogOpen(false);
                            }}
                        >
                            Okay
                        </DrcButton>
                    </>
                }
            >
                {getInfo()}
            </DrcDialog>
        </>
    );
}

export default DrcPwaInstallHelper;
