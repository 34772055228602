import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities } from '@/legacy/DriscollsReactUtilities';
import DrcHeader from './DrcHeader';

/**
 * @description Provides authentication security to the children components
 * @property {*} children Header node that needs to be wrapped under authentication
 * @property {String} title header title text
 * @property {Boolean} noLogo Flag to check if logo should be added to header or not.
 * @property {*} logo Logo Image
 * @property {Object} style Custom style object
 * @property {Boolean} fullWidth True or False to indicate if it should be given width 100%
 * @property {String} anchor Side from which the drawer menu will appear ( 'bottom' or 'left' or 'right' or 'top')
 * @example
 * <DrcSecureHeader
        title={"Site Name"}
        allLinks={[]}
        fullWidth={true}
        logo={
            <Link to="/">
                <DrcImage src={LogoPng} webp={LogoWebP} alt="Driscoll's Logo" />
            </Link>
        }
        className={classes.header}
    >
        <DrcButton isText to="/Page/">
            {DrcIcons.GetBerryIcon("BLUE")}
        </DrcButton>
    </DrcSecureHeader>
 * @donotprint true
 * @category Security
 * @tags security header permission group links
 * @class DrcSecureHeader
 * @extends {React.Component}
 */
class DrcSecureHeader extends React.Component {
    /**
     * Creates an instance of DrcSecureHeader.
     * @param {*} props
     * @memberof DrcSecureHeader
     */
    constructor(props) {
        super(props);
        this.state = {
            allowedLinks: this.props.allowAllLinks ? this.props.allLinks || [] : []
        };

        this.checkAuthentication = this.checkAuthentication.bind(this);
    }

    /**
     * @description method to check for user authentication
     * @returns {*}
     * @memberof DrcSecureHeader
     */
    checkAuthentication() {
        if (!this.props.authState) {
            if (this.state.allowedLinks.length > 0) {
                this.setState({ allowedLinks: [] });
            }

            return;
        }

        let token = this.props.authState?.accessToken;

        if (!token || token.length <= 0) {
            if (this.state.allowedLinks.length > 0) {
                this.setState({ allowedLinks: [] });
            }
        } else {
            var allowedLinks = [];

            var matchedLinks = this.props.allLinks.filter((l) => {
                if ((l.requiresGroups || []).length > 0) {
                    return l.requiresGroups.some((rg) => DuAuthenticationUtilities.IsInGroup(token, rg));
                }

                return true;
            });

            if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])) {
                allowedLinks = matchedLinks;
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_REGULAR_GROUPS || [])) {
                allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin);
                allowedLinks = allowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin);
                    return link;
                });
            } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_READ_ONLY_GROUPS || [])) {
                allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin && !link.requiresRegular);
                allowedLinks = allowedLinks.map((link) => {
                    link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin && !subLink.requiresRegular);
                    return link;
                });
            }

            if (this.state.allowedLinks.length !== allowedLinks.length) {
                this.setState({ allowedLinks });
            }
        }
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.checkAuthentication();
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.checkAuthentication();
    }

    /**
     *
     * @returns {*}
     * @memberof DrcSecureHeader
     */
    render() {
        const { children, title, noLogo, style, fullWidth, logo, className, showFilterControl, showSavedFiltersPopup, anchor } = this.props;

        return (
            <DrcHeader
                title={title}
                noLogo={noLogo}
                showSavedFiltersPopup={showSavedFiltersPopup}
                allLinks={this.state.allowedLinks}
                style={style}
                showFilterControl={showFilterControl}
                fullWidth={fullWidth}
                logo={logo}
                className={className}
                anchor={anchor}
            >
                {children}
            </DrcHeader>
        );
    }
}

export default withOktaAuth(DrcSecureHeader);
