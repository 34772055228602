import DuDateUtilities from './DuDateUtilities';
/**
 * @description method to upload files
 * @method createFileDrop
 * @param {*} element
 * @param {*} returnFunction callback function having details files upload
 * @param {*} statusFunction status function specifying the status details of the each file upload
 * @param {*} acceptedFiles array of accepted file extensions
 */
var createFileDrop = function (element, returnFunction, statusFunction, acceptedFiles) {
    element.addEventListener(
        'drop',
        (e) => {
            handleFileDrop(e, returnFunction, statusFunction, acceptedFiles);
        },
        false
    );
};

/**
 * @description method to handle uploaded files to validate
 * @method handleFileDrop
 * @param {*} e event
 * @param {*} returnFunction callback function having details files upload
 * @param {*} statusFunction status function specifying the status details of the each file upload
 * @param {*} acceptedFiles array of accepted file extensions
 */
var handleFileDrop = function (e, returnFunction, statusFunction, acceptedFiles) {
    e.preventDefault();
    e.stopPropagation();

    var files = e.dataTransfer.files,
        f = files[0];
    readFile(f, returnFunction, statusFunction, acceptedFiles);
};

/**
 * @description method to set status of uploaded file
 * @method setStatus
 * @param {*} statusFunction status function specifying the status details of the file upload
 * @param {*} percentDone percentage done
 * @param {*} message status message
 */
const setStatus = async (statusFunction, percentDone, message) => {
    const isIndeterminate = percentDone < 0;
    statusFunction({ isIndeterminate, percentDone, message });
};

/**
 *
 * @description method to validate uploaded files
 * @method handleFiles
 * @param {*} fileList list of files
 * @param {*} returnFunction callback function specifying details of uploaded files validation
 * @param {*} statusFunction status function specifying the status details of the each file validation
 * @param {*} acceptedFiles array of accepted file extensions
 */
var handleFiles = function (fileList, returnFunction, statusFunction, acceptedFiles) {
    if (!fileList || fileList.length <= 0) {
        return;
    }

    var f = fileList[0];
    readFile(f, returnFunction, statusFunction, acceptedFiles);
};

/**
 *
 * @description method for reading & validating the uploaded file
 * @method readFile
 * @param {*} file file
 * @param {*} returnFunction callback function specifying details of uploaded file validation
 * @param {*} statusFunction status function specifying the status details of file validation
 * @param {*} acceptedFiles array of accepted file extensions
 */
var readFile = async (file, returnFunction, statusFunction, acceptedFiles) => {
    var startTime = new Date();

    var extension = file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.') + 1) : '';
    if (!acceptedFiles.includes('.' + extension)) {
        returnFunction(null, { fileName: file.name, msElapsed: 0, timeElapsed: 'Failed', message: 'File Extension is not valid, please upload a document.' });
        return;
    }

    setStatus(statusFunction, -1, 'Starting to Open File');
    var endTime = new Date();

    returnFunction(file, { fileName: file.name, msElapsed: endTime - startTime, timeElapsed: DuDateUtilities.TimeSpanToString(startTime, endTime) });
};

/**
 *
 * @description method to read rows of a excel file
 * @method readRows
 * @param {*} workbook workbook name
 * @param {*} sheetName sheet name
 * @param {*} statusFunction
 */
const readRows = async (workbook, sheetName, statusFunction) => {
    return new Promise((resolve) => {
        var rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });
        resolve(rows);
    });
};

/**
 *
 * @constant DuUploadUtilities
 */
const DuUploadUtilities = {
    CreateFileDrop: createFileDrop,
    ReadRows: readRows,
    HandleFiles: handleFiles
};

export default DuUploadUtilities;
