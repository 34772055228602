import { Middleware } from '@/legacy/OneRing';

const attributeNameExistsMapping = [Middleware.CreateMapping('exists', 'Exists'), Middleware.CreateArrayMapping('Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AttributeNameExists', 'GET')) {
        Middleware.RegisterMapping('AttributeNameExists', 'GET', attributeNameExistsMapping);
    }
}

const attributeNameExists = {
    ConfigureMiddleware
};

export default attributeNameExists;
