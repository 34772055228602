import axios from 'axios';

const API_COOL_DOWN_IN_MILLISECONDS = 1000;
const API_DUPLICATE_CALLS_ALLOWED = 5;

var dataHandlers = {};
var requests = {};

//TODO: We should keep track of APIs that fail here and batch them up so we can send them when the APIs start working again.
//      Keep track in a cookie as well.
//var failedApiCalls = [];

var handleError = function(error, showErrorDialogAction) {
    if (error.response) {
        if (error.response.status >= 500) {
            //Probably a condition was not met, print out the request object
            console.error('HTTP Status Indicated Server Failure (%i)\n%s', error.response.status, error.response.data);

            if (showErrorDialogAction) {
                showErrorDialogAction('Network Failure', "Server related issue, please try again in a little bit. If this keeps happening please contact Driscoll's IT.");
            }
        } else if (error.response.status >= 400) {
            //Probably a condition was not met, print out the request object
            console.error('HTTP Status Indicated Local Failure (%i)\n%s', error.response.status, error.response.data);

            if (showErrorDialogAction) {
                showErrorDialogAction(
                    'Network Failure',
                    "Website related issue, please verify data and re-try the last action. If this keeps happening please contact Driscoll's IT."
                );
            }
        } else {
            //Maybe a data error, give details on where in code this occurred.
            console.error('Error Processing Data Returned (%i)\n%s', error.response.status, error.response.data);

            if (showErrorDialogAction) {
                showErrorDialogAction('Network Failure', "Unknown Error. If this keeps happening please contact Driscoll's IT.");
            }
        }
    } else {
        //Network error or CORS issue not sure what we can say much more than that.
        console.error('Network Error, could be CORS could be bad internet connection');

        if (showErrorDialogAction) {
            showErrorDialogAction(
                'Network Error',
                "Network connection interrupted or an security policy has blocked this request. If this keeps happening please contact Driscoll's IT."
            );
        }
    }
};

var HandleLoadingApi = function(promise, openLoadingScreenAction, closeLoadingScreenAction, showErrorDialogAction) {
    openLoadingScreenAction();

    promise
        .then(() => {
            closeLoadingScreenAction();
        })
        .catch((error) => {
            closeLoadingScreenAction();
            handleError(error, showErrorDialogAction);
        });

    return promise;
};

var registerFunctions = function(key, data, handlers) {
    var handlerKey = Object.keys(handlers)[0];
    var keyLower = key.toLowerCase();
    var currentHandlers = dataHandlers[keyLower];
    if (currentHandlers) {
        if (currentHandlers.handlers[handlerKey]) {
            console.error('Function already registered.');
            return;
        } else {
            dataHandlers[keyLower].handlers = { ...dataHandlers[keyLower].handlers, ...handlers };
            return;
        }
    }
    dataHandlers[keyLower] = { data, handlers };
};

var getExternalListName = function(mapping) {
    var returnValue = null;
    mapping.forEach((row) => {
        if (row.IsArray) {
            returnValue = row.ExternalVariableName;
            return;
        }
    });
    return returnValue;
};

var updateFunctionData = function(key, data) {
    var keyLower = key.toLowerCase();
    if (!dataHandlers[keyLower]) {
        console.error('Function not already registered.');
        return;
    }
    dataHandlers[keyLower] = { data, handlers: dataHandlers[keyLower].handlers };
};

var createHandler = function(method, handler) {
    var handlerObj = { [method]: handler };
    return handlerObj;
};

var checkRegisteredFunctions = function(key) {
    var keyLower = key.toLowerCase();
    if (dataHandlers[keyLower]) {
        return true;
    }
    return false;
};

var isEquivalent = function(a, b) {
    // Checks for undefined payloads, these
    // cause Object.getOwnPropertyNames() to fail
    if (a === undefined || b === undefined) {
        if (a === undefined && b === undefined) {
            return true;
        } else {
            return false;
        }
    }

    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
};

var handleAPICall = async function(key, token, url, method, requestObj, props, mapping, args, defaultRequestObj, headerObj) {
    return new Promise(function(resolve, reject) {
        var keyLower = key.toLowerCase();
        var time = new Date();
        var resetRedundancy = false;

        if (requests[url] && isEquivalent(requests[url].request, requestObj)) {
            if (time - requests[url].occurred <= API_COOL_DOWN_IN_MILLISECONDS && requests[url].occurrences >= API_DUPLICATE_CALLS_ALLOWED) {
                console.groupCollapsed('API Redundancy Detected');
                console.error('Found a duplicate API call');
                console.error(url);
                console.error(requestObj);
                console.error('This error is likely caused by a bug, please report to a friendly developer');
                console.groupEnd();

                reject();
                return;
            }

            resetRedundancy = true;
        }

        var occurrences = requests[url] && !resetRedundancy ? requests[url].occurrences + 1 : 1;
        requests[url] = { occurred: time, request: requestObj, occurrences: occurrences };

        props.showLoadingScreen(props.loadingMessage);

        if (checkRegisteredFunctions(keyLower)) {
            console.groupCollapsed('Fake API');
            console.log('Logging Fake Data usage, no cause for alarm');
            console.groupCollapsed(key);
            console.warn('%s with url: %s', key, url);
            console.log('Request Object: ', requestObj);
            console.groupEnd();
            console.groupEnd();

            if ((url || '').length > 0) {
                console.group('Fake API');
                console.error('We are not expecting to use fake data for %s as it has a url of %s', key, url);
                console.groupEnd();
            }

            callFakeAPI(keyLower, url, requestObj, method, mapping, args)
                .then((data) => {
                    props.hideLoadingScreen();
                    resolve(data);
                    return;
                })
                .catch((error) => {
                    props.hideLoadingScreen();
                    //TODO: Figure out if we want to show the error modal here or not, might want to have that configurable
                    // reject(data);
                    let data = { data: error };
                    reject(data);
                    throw error;
                });
        } else {
            var request = defaultRequestObj !== null ? { ...defaultRequestObj, ...requestObj } : requestObj;
            callAPI(token, url, request, method, headerObj)
                .then((data) => {
                    props.hideLoadingScreen();
                    resolve(data);
                    return;
                })
                .catch((error) => {
                    props.hideLoadingScreen();
                    if (error.response) {
                        if (error.response.status >= 400) {
                            let data = { data: error };
                            reject(data);
                        }
                    }
                    //TODO: Figure out if we want to show the error modal here or not, might want to have that configurable
                    throw error;
                });
        }
    });
};

var callAPI = function(token, url, requestObj, method, headerObj) {
    return axios({
        method: method,
        url: url,
        data: requestObj,
        headers: token ? { Authorization: (token.length <= 100 ? 'Basic ' : 'Bearer ') + token, ...headerObj } : headerObj ? headerObj : null
    });
};

var callFakeAPI = async function(name, url, requestObj, method, mapping, args) {
    return new Promise(function(resolve, reject) {
        var nameLower = name.toLowerCase();
        var handler = dataHandlers[nameLower];
        var data = handler.handlers[method](handler.data, requestObj, mapping, args);
        if (method === ('POST' || 'PUT' || 'DELETE')) {
            updateFunctionData(name, data);
        }
        resolve(data);
    });
};

var extVarName;
var filterToOne = function(data, requestObj, mapping, args) {
    const filters = Object.entries(requestObj);
    var returnObj = {};
    var dataArray = [];
    // Check to see if payload is empty
    if (Object.entries(requestObj).length === 0 && requestObj.constructor === Object) {
        // Do we want to return empty obj or null?
        return returnObj;
    }

    mapping.forEach(function(map) {
        if (map.ExternalArrayVariableNameToCount.length > 0) {
            externalArrayVariableNameToCount = map.ExternalVariableName;
        }
        if (map.IsArray) {
            dataArray = data[map.ExternalVariableName];
            extVarName = map.ExternalVariableName;
        }
    });

    dataArray.forEach((item) => {
        var retValue = true;
        filters.forEach(function(filter) {
            if (filter[0] === 'BerryTypes' && item.BerryTypes !== undefined) {
                if (!checkObject(item.BerryTypes, filter[1])) {
                    retValue = false;
                }
            } else if (item[filter[0]] !== filter[1]) {
                retValue = false;
                return;
            }
        });
        if (retValue) {
            returnObj = item;
            return;
        }
    });

    return returnObj;
};

var externalArrayVariableNameToCount = '';
// var extVarName = '';

var myFilter = function(data, requestObj, mapping, args) {
    const filters = Object.entries(requestObj);
    if (Object.entries(requestObj).length === 0 && requestObj.constructor === Object) {
        return data;
    }

    // Add ability to use multiple arrays
    var dataArray = [];
    // var externalArrayVariableNameToCount = '';

    mapping.forEach(function(map) {
        if (map.ExternalArrayVariableNameToCount.length > 0) {
            externalArrayVariableNameToCount = map.ExternalVariableName;
        }
        if (map.IsArray) {
            dataArray = data[map.ExternalVariableName];
            extVarName = map.ExternalVariableName;
        }
    });

    var filteredData = dataArray.filter((item) => {
        var retValue = true;
        filters.forEach(function(filter) {
            if (filter[0] === 'BerryTypes' && item.BerryTypes !== undefined) {
                if (!checkObject(item.BerryTypes, filter[1])) {
                    retValue = false;
                }
            } else if (item[filter[0]] !== filter[1]) {
                retValue = false;
                return;
            }
        });
        return retValue;
    });

    var returnObj = {
        ...data,
        [extVarName]: filteredData,
        [externalArrayVariableNameToCount || 'Count']: filteredData.length
    };

    return returnObj;
};

var noOp = function(data, requestObj, mapping, args) {
    return data;
};

/**
 * Update a Single Item in a list of data.
 *
 * @param {Array}  data       The Data from the fake data that was provided in the mapping.
 * @param {Object} requestObj The Request Object sent to the API, but can be used with the fake data as well.
 * @param {Object} mapping    The mapping object that is passed in from the middleware to aide in additional mapping conversion if needed.
 * @param {Object} args       Requires Variable in object: MatchVariableName. This is the variable name for which to index on to find the correct item to update.
 */
var updateOne = function(data, requestObj, mapping, args) {
    var listName = getExternalListName(mapping);
    if (listName === null) {
        console.error('No list registered in data.');
        return;
    }

    var indexName = args.MatchVariableName || args.IndexVarName || '';
    var isAltered = false;

    if (indexName.length <= 0) {
        mapping.some(function(map) {
            if (map.IsIndex) {
                indexName = map.ExternalVariableName;
                return true;
            }
            return false;
        });
    }

    if (indexName.length <= 0) {
        console.error('No MatchVariableName set in arguments passed in.');
        return;
    }

    var returnObj = {
        ...data,
        [listName]: data[listName].map((row) => {
            if (row[indexName] === requestObj[indexName]) {
                isAltered = true;
                return { ...row, ...requestObj };
            }
            return row;
        })
    };

    if (!isAltered) {
        returnObj[listName].push({ ...requestObj });
    }

    return returnObj;
};

var checkObject = function(item, filter) {
    var found = false;
    for (var i = 0; i < item.length; i++) {
        if (item[i].value === filter) {
            found = true;
            break;
        }
    }
    return found;
};

const ApiUtilities = {
    HandleApiError: handleError,
    HandleLoadingApi: HandleLoadingApi,
    RegisterFunctions: registerFunctions,
    CheckRegisteredFunctions: checkRegisteredFunctions,
    CreateHandler: createHandler,
    HandleAPICall: handleAPICall,
    Filter: myFilter,
    FilterToOne: filterToOne,
    NoOp: noOp,
    UpdateOne: updateOne
};

export default ApiUtilities;
