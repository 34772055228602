import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { DrcButton, DrcIcons } from '../legacy/DriscollsReactComponents';
import { DuIcons } from '../legacy/DriscollsReactUtilities';
import { berryMap } from '../data/constants';
import { connect } from 'react-redux';
import { setEntityMetaData } from '../apps/MasterData/actions/selectionActions';

var styles = () => ({
    headerButtons: {
        float: 'right',
        marginTop: '-42px',
        padding: '6px',
        minWidth: 0,
        margin: 4,
        marginRight: '60px',
        border: 'none !important',
        textTransform: 'capitalize',
        '& svg': {
            fontSize: 24
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 500,
            width: 120,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    berryBtn: {
        margin: '4px auto !important',
        border: 'none !important'
    },
    berryIcon: {
        padding: '0px 8px 0px 0px'
    },
    berryLabel: {
        padding: '0px 0px 4px 0px',
        fontSize: '1.2rem'
    }
});

class BerryToggle extends Component {
    state = {
        anchorEl: null
    };

    berries = Object.values(berryMap);

    handleBerryClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    setBerry = (berry) => () => {
        this.handleClose();
        if (this.props.entityMetaData && this.props.entityMetaData.EntityName === 'Selection') {
            let data = this.props.entityMetaData.Fields;
            this.props.setEntityMetaData({ ...this.props.entityMetaData, Fields: data, berry });
        }
        this.props.setBerry(berry);
    };

    render() {
        const { classes, berry } = this.props;
        const { anchorEl } = this.state;
        return (
            <>
                <DrcButton isSecondary aria-describedby={'Berry-popover'} className={classes.headerButtons} noStyle onClick={this.handleBerryClick}>
                    <span className={classes.berryIcon}>{DrcIcons.GetBerryIcon(berry)}</span>
                    <span className={classes.berryLabel}>{DuIcons.GetBerryLabel(berry)}</span>
                </DrcButton>
                <Popover
                    id={'Berry-popover'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    className={classes.popover}
                >
                    {this.berries.map((berryItem) => (
                        <DrcButton key={berryItem} isSecondary onClick={this.setBerry(berryItem)} className={classes.berryBtn}>
                            <span className={classes.berryIcon}>{DrcIcons.GetBerryIcon(berryItem)}</span>
                            <span className={classes.berryLabel}>{DuIcons.GetBerryLabel(berryItem)}</span>
                        </DrcButton>
                    ))}
                </Popover>
            </>
        );
    }
}
function mapStateToProps({ masterDataReducer }) {
    return {
        entityMetaData: masterDataReducer.selectionReducer.metaData,
        floweringType: masterDataReducer.selectionReducer.floweringType
    };
}

const mapDispatchToProps = (dispatch) => ({
    setEntityMetaData: (data) => dispatch(setEntityMetaData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BerryToggle));
