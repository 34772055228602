import React from 'react';
import { DuDateUtilities, DuAuthenticationUtilities } from '../legacy/DriscollsReactUtilities';
import { DrcTooltip } from '../legacy/DriscollsReactComponents';

/**
 *
 * @param {Array<Object>} rows
 * @param {String} optionLabel
 * @param {String} optionValue
 */
export const makeOptionList = (rows, optionLabel, optionValue) =>
    rows.map((e) => {
        e.label = e[optionLabel];
        e.value = e[optionValue];
        return e;
    });

/**
 *
 * @param {Array<Object>} rows
 * @param {Array<String>} dateKeys
 * @param {Array<String>} booleanKeys
 * @param {Boolean} addActionColumn
 */
export const formatApiResponse = (rows, dateKeys, booleanKeys, addActionColumn = false, overrideBoolean = false) => {
    const dateTimeColumns = ['createdDateTime', 'modifiedDateTime'];
    //Since dateKeys & booleanKeys are coming from constants so need to check if they are in row or not
    return rows.map((row) => {
        dateKeys.map((key) => {
            if (row[key])
                return (row[key] = dateTimeColumns.includes(key.toLowerCase())
                    ? row[key].indexOf('T') > -1
                        ? DuDateUtilities.FormatDateTimeFromIso(row[key])
                        : row[key]
                    : row[key].indexOf('T') > -1
                    ? DuDateUtilities.FormatDateFromIso(row[key])
                    : row[key]);
            return null;
        });
        booleanKeys.map((key) => {
            if (row[key]) {
                return !overrideBoolean ? (row[key] = row[key] ? 'Yes' : 'No') : row[key];
            }
            return null;
        });
        if (addActionColumn) {
            row.Actions = 'Actions';
        }
        return row;
    });
};

/**
 *
 * @param {String} text
 */
export const columnTooltip = (text) => (
    <DrcTooltip tipText={text}>
        <span>{text}</span>
    </DrcTooltip>
);

export const hasEditAccess = (token) => {
    const allGroupsWithEditAccess = (window.config.OKTA_APP_ADMIN || []).concat(
        window.config.OKTA_RD_ADMIN_GROUPS || [],
        window.config.OKTA_RD_REGULAR_GROUPS || [],
        window.config.OKTA_RD_ENTITY_ADMIN || [],
    );

    return DuAuthenticationUtilities.IsInGroup(token, allGroupsWithEditAccess || []);
};
