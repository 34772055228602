import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DrcMediaQueries from '../Utilities/DrcMediaQueries';
import { DuThemeUtilities } from '@/legacy/DriscollsReactUtilities';
import DcrTornPaper from './DrcTornPaper';


const useStyles = makeStyles({
    mainContainer:{
        ['@media ' + DrcMediaQueries.mobileL]: {
            margin: '60px 10px 20px 10px'
        }
    },
    titleContainer: {
        top: -15,
        left: -15,
        position: 'absolute',
        color: DuThemeUtilities.DefaultColors.primary.green,
        ['@media ' + DrcMediaQueries.mobileL]: {
            top: -35,
            left: -10,
        },
        '& > div': {
            fontSize: '2rem'
        }
    }
});

const DrcTornPaperWithTitle = ({children, ...props}) =>{
    const classes = useStyles(props);

    return (
        <div className={classes.paperContainer}>
            <DcrTornPaper padding='40px 20px 10px' heightOfShreds={props.titleHeightOfShreds || 8} containerClassName={classes.mainContainer}>
                <DcrTornPaper width={250} margin={0} padding={10} heightOfShreds={4} backgroundLight='#ebebe8' backgroundDark='#292929' containerClassName={classes.titleContainer}>{props.title}</DcrTornPaper>
                {children}
            </DcrTornPaper>
        </div>
    );
};

export default DrcTornPaperWithTitle;
