import { Middleware } from '@/legacy/OneRing';

const entityNameExistsMapping = [
    Middleware.CreateMapping('exists', 'Exists'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('EntityNameExists', 'GET')) {
        Middleware.RegisterMapping('EntityNameExists', 'GET', entityNameExistsMapping);
    }
}

const entityNameExists = {
    ConfigureMiddleware
};

export default entityNameExists;
