import React, { Component } from 'react';
import { withOktaAuth, SecureRoute } from '@okta/okta-react';
import DrcPageNotAuthorized from '../Pages/DrcPageNotAuthorized';
import DrcPageLoadingUser from '../Pages/DrcPageLoadingUser';
import { DuAuthenticationUtilities } from '@/legacy/DriscollsReactUtilities';

class DrcSecureGroupRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: null,
            groups: null
        };

        this.checkForGroups = this.checkForGroups.bind(this);
        this.renderWrapper = this.renderWrapper.bind(this);
    }

    async checkForGroups() {
        try {
            let token = await this.props.oktaAuth.getAccessToken();
            if (!token || token.length <= 0) {
                if ((this.state.groups && this.state.groups.length != 0) || this.state.isAuthenticated != false) {
                    this.setState({ groups: [], isAuthenticated: false });
                }
            } else {
                var groups = DuAuthenticationUtilities.GetGroups(token) || [];

                if (!this.state.groups || this.state.groups.length != groups.length || this.state.isAuthenticated != true) {
                    this.setState({ groups, isAuthenticated: true });
                }
            }
        } catch (err) {
            console.error('ERROR: Access Token not available', err);
                if (this.state.isAuthenticated != false) {
                    this.setState({ groups: [], isAuthenticated: false });
                }
        }
    }

    componentDidUpdate() {
        this.checkForGroups();
    }

    componentDidMount() {
        this.checkForGroups();
    }

    renderWrapper(renderProps) {
        const C = this.props.component;
        return <C {...renderProps} role={this.props.groups || []} />;
    }

    render() {
        const { exact, path, groupsAllowed, loadingPage, unauthorizedPage } = this.props;

        if (this.state.isAuthenticated === false) {
            return unauthorizedPage || <DrcPageNotAuthorized />;
        } else if (this.state.isAuthenticated === null || !this.state.groups) {
            return loadingPage || <DrcPageLoadingUser />;
        }

        //Use the groups that are available now.
        var requiresGroup = (groupsAllowed || []).length > 0;
        var userGroups = this.state.groups || [];
        var isAllowed = requiresGroup ? userGroups.some((r) => groupsAllowed.indexOf(r) >= 0) : true;

        //If we find groups or find none but token was successfully received then show Not Authorized page.
        return isAllowed ? <SecureRoute path={path} render={this.renderWrapper} exact={exact} /> : unauthorizedPage || <DrcPageNotAuthorized />;
    }
}

export default withOktaAuth(DrcSecureGroupRoute);
