import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { DrcMain } from '@/legacy/DriscollsReactComponents';
import { Redirect } from 'react-router-dom';

class LogOut extends Component {
    constructor(props) {
        super(props);

        this.state = { shouldRedirectHome: false };

        this.props.oktaAuth.signOut('/');
        localStorage.clear();
    }

    render() {
        return this.state.shouldRedirectHome ? (
            <Redirect to="/" />
        ) : (
            <DrcMain>
                <h3>Please Wait... Logging you out shortly...</h3>
            </DrcMain>
        );
    }
}

export default withOktaAuth(LogOut);
