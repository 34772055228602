import { Middleware } from '@/legacy/OneRing';

const fpHeaderDataMappings = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('ItemCode', 'ItemCode'),
    Middleware.CreateMapping('FootprintCode', 'FootprintCode'),
    Middleware.CreateMapping('CasePerLevel', 'CasesPerLevel'),
    Middleware.CreateMapping('Length', 'Length'),
    Middleware.CreateMapping('Width', 'Width'),
    Middleware.CreateMapping('Height', 'Height'),
    Middleware.CreateMapping('PalletStackHeight', 'PalletStackHeight'),
    Middleware.CreateMapping('DefaultUnitType', 'DefaultUnitType'),
    Middleware.CreateMapping('IsDefault', 'IsDefault'),
    Middleware.CreateMapping('ItemFootprintId', 'ItemFootprintId'),
    Middleware.CreateArrayMapping('Results', 'Data')
];
const fpHeaderDataPOSTMappings = [
    Middleware.CreateMapping('ItemId', 'ItemId'),
    Middleware.CreateMapping('ItemType', 'ItemType'),
    Middleware.CreateMapping('ItemCode', 'ItemCode'),
    Middleware.CreateMapping('FootprintCode', 'FootprintCode'),
    Middleware.CreateMapping('CasePerLevel', 'CasePerLevel'),
    Middleware.CreateMapping('Length', 'Length'),
    Middleware.CreateMapping('Width', 'Width'),
    Middleware.CreateMapping('Height', 'Height'),
    Middleware.CreateMapping('PalletStackHeight', 'PalletStackHeight'),
    Middleware.CreateMapping('DefaultUnitType', 'DefaultUnitType'),
    Middleware.CreateMapping('ItemFootprintId', 'ItemFootprintId'),
    Middleware.CreateMapping('IsDefault', 'IsDefault'),
    Middleware.CreateArrayMapping('Results', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('FPHeaderData', 'GET')) {
        Middleware.RegisterMapping('FPHeaderData', 'GET', fpHeaderDataMappings);
        Middleware.RegisterMapping('FPHeaderData', 'POST', fpHeaderDataPOSTMappings);
    }
}

const FPHeaderData = {
    ConfigureMiddleware
};

export default FPHeaderData;
