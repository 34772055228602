import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import DrcLegend from './DrcLegend';
import DrcButton from './DrcButton';
import DrcMain from './DrcMain';
import DrcImage from './DrcImage';
import { makeStyles } from '@material-ui/core/styles';
import { DuThemeUtilities } from '@/legacy/DriscollsReactUtilities';

const useStyles = makeStyles({
    header: {
        width: '100%',
        marginTop: 0,
        padding: '0 0 5px',
        fontSize: '2rem',
        textAlign: 'center'
    },
    headerError: {
        color: DuThemeUtilities.DefaultColors.primary.red,
        borderColor: DuThemeUtilities.DefaultColors.primary.red
    },
    loginButton: {
        width: '100%',
        margin: '12px 0 0',
        backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
        borderColor: DuThemeUtilities.DefaultColors.primary.red,
        '&:hover': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            borderColor: DuThemeUtilities.DefaultColors.primary.red
        }
    },
    image: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        '@media (prefers-color-scheme: dark)': {
            opacity: 0.2
        }
    }
});

/**
 * @description This DrcImplicitCallback Component is used to get the token when logging in to Okta.
 * @example
 * <Route
 *      path="/implicit/callback"
 *       render={(props) => (
 *          <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
 *      )}
 * />
 * @donotprint true
 * @category Security
 * @tags security login okta callback redirect
 * @property {jsx} backgroundPng background image to show while loading that is a png
 * @property {jsx} backgroundWebP background image to show while loading that is a webp, this allows for higher compression without loss of image quality
 * @property {string} redirect the url to redirect to once complete, leaving this alone will default to /InitializeApplication/ which is good for most uses
 * @class DrcImplicitCallback
 * @extends {React.Component}
 */
function DrcImplicitCallback(props) {
    const classes = useStyles();
    let history = useHistory();
    const { authState } = useOktaAuth();

    const [isError, setIsError] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (!authState || authState?.isAuthenticated === true) {
            return;
        }

        let url = history.location.search;
        let isUrlError = false;

        if (url.indexOf('state=') < 0) {
            isUrlError = true;
        }

        let callbackDetails = url.substring(url.indexOf('state='));
        let callbackChunks = callbackDetails.split('&');

        let error = 'Unknown Error';
        let errorDescription = 'An unknown error has occurred, please try to login again. If this continues please seek assistance.';

        try {
            callbackChunks.forEach((chunk) => {
                if (chunk.includes('error=')) {
                    isUrlError = true;
                    let errorTitle = chunk.split('=')[1];

                    errorTitle = errorTitle.replace(/_/gi, ' ').trim();
                    error = errorTitle.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
                } else if (chunk.includes('error_description=')) {
                    isUrlError = true;
                    let errorInfo = chunk.split('=')[1];

                    errorDescription = errorInfo.replace(/\+|#|\//gi, ' ').trim();
                }
            });
        } catch {
            errorDescription = 'An unknown error has occurred, please try to login again. If this continues please seek assistance.';
        }

        setTitle(isUrlError ? error : 'Successfully Authorized');
        setDescription(isUrlError ? errorDescription : 'You are now logged in, we will take you to the application now.');
        setIsError(isUrlError);
    }, [authState]);

    return (
        <DrcMain maxWidth={600}>
            <DrcImage src={props.backgroundPng} webp={props.backgroundWebP} className={classes.image} alt="" />
            <DrcLegend className={`${classes.header} ${isError ? classes.headerError : ''} ${props.legendClassName ? props.legendClassName : null}`}>
                {!authState ? "Hold tight, we're authorizing you now" : !isError ? 'Successfully Authorized' : title || 'Unknown Error'}
            </DrcLegend>
            <p>{description}</p>
            <LoginCallback />
            {!!authState ? (
                authState.isAuthenticated ? (
                    <Redirect to={props.redirect || '/InitializeApplication/'} />
                ) : isError ? (
                    <DrcButton noStyle isPrimary to="/" className={classes.loginButton}>
                        Back to Login
                    </DrcButton>
                ) : null
            ) : null}
        </DrcMain>
    );
}

export default DrcImplicitCallback;
