import { format, parseISO } from 'date-fns';

const DATE_FORMAT = 'MMM-dd-yyyy';
const DATE_TIME_FORMAT = 'MMM-dd-yyyy hh:mm a';
const DATE_TIME_SECONDS_FORMAT = 'MMM-dd-yyyy hh:mm:ss a';
const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const NULL_TIME = 'T00:00:00';
const BEFORE_MIDNIGHT_TIME = 'T23:59:59';

/* To explain the terms in the formula, the Javascript timestamp has milliseconds (*1000) 
which accounts for the 1000. There are 60*60*24 seconds in one day = 86400. Finally, 
Excel dates start on Jan 1 1900, and Javascript starts on Jan 1 1970. 
There are 25567 days between Jan 1 1900 and Jan 1 1970. */
const EXCEL_DAYS_DIFFERENCE = 25568;
const MIN_DATE = new Date(0);

/**
 *@description Calculate the week number of a date
 * @param {Date} date the date of which want to know the week number.
 * @return {number} Number of week of the date given. 
 */
const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    // eslint-disable-next-line no-undef
    const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};


/**
 *@description Validate if the object given is a Date object
 *
 * @param {*} date object to validate.
 * @return {boolean} true if date is valid
 */
const isValidDate = (date) => {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        if (isNaN(date.getTime())) {
            return false; //It is a date object but not valid;
        }

        return true; // valid date
    }

    return false; // not even the right object
};

/**
 *@description Convert a date to string in one of the following formats MMM-dd-yyyy hh:mm a or MMM-dd-yyyy.
 * @param {Date} date date to convert.
 * @param {boolean} [showTime=false] indicates if the time portion will be included in the result.
 * @return {string} date converted to string or null if the date is invalid.
 */
const toString = (date, showTime = false) => {
    if (date !== null && isValidDate(date)) {
        return format(date, showTime ? DATE_TIME_FORMAT : DATE_FORMAT);
    } else {
        return null;
    }
};


/**
 *@description Convert ISO dates without time portion (with time portion use formatDateTimeFromIso) to string in the following format MMM-dd-yyyy.
 * @param {Date} date date to convert
 * @param {string} [failPlaceholder='N/A'] dtring to return in the case the conversion fails.
 * @return {string} date converted from ISO or failPlaceholder if the conversion fails.
 */
const formatDateFromIso = (date, failPlaceholder = 'N/A') => {
    if (!!date && (date || '').length > 0) {
        try {
            var dateFixed = date.toString().trim();

            //Fixing date to have a T instead of a space that are correct in some ISO 8601
            if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
                dateFixed = dateFixed.replace(' ', 'T');
            }

            //All dates should be coming in as UTC (Zulu)
            //   except for those that have the null time which allows us to see dates only irregardless of time zone.
            if (!dateFixed.includes(NULL_TIME) && !dateFixed.includes('Z') && dateFixed.includes('T')) {
                dateFixed += 'Z';
            }

            var parsedIso = parseISO(dateFixed);

            return format(parsedIso, DATE_FORMAT);
        } catch (error) {
            console.error('Got an incorrect date format');

            return failPlaceholder;
        }
    } else {
        return failPlaceholder;
    }
};

/**
 *@description Convert ISO dates with time portion (without time portion use formatDateTimeFromIso) to string in the following format MMM-dd-yyyy hh:mm a.
 * @param {Date} date date to convert.
 * @param {string} [failPlaceholder='N/A'] string to return in the case the conversion fails.
 * @param {boolean} showSeconds indicates if the result will contain seconds
 * @return {string} date converted from ISO or failPlaceholder if the conversion fails
 */
const formatDateTimeFromIso = (date, showSeconds = false, failPlaceholder = 'N/A') => {
    if (!!date && (date || '').length > 0) {
        try {
            var dateFixed = date.toString().trim();

            //Fixing date to have a T instead of a space that are correct in some ISO 8601
            if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
                dateFixed = dateFixed.replace(' ', 'T');
            }

            //All dates should be coming in as UTC (Zulu)
            if (!dateFixed.includes('Z') && dateFixed.includes('T')) {
                dateFixed += 'Z';
            }

            var parsedIso = parseISO(dateFixed);

            return format(parsedIso, showSeconds ? DATE_TIME_SECONDS_FORMAT : DATE_TIME_FORMAT);
        } catch (error) {
            console.error('Got an incorrect date format');

            return failPlaceholder;
        }
    } else {
        return failPlaceholder;
    }
};


/**
 *@description Convert date string with the format MMM-dd-yyyy.
 * @param {Date} date dateObj to convert.
 * @param {boolean} convertTime indicates if time portion will be included in the result.
 * @param {boolean} convertToPretty indicates if the date will have the format MMM-dd-yyyy.
 * @return {string} date converted to string or dateObj if convertToPretty is false.
 */
const returnDate = (dateObj, convertTime, convertToPretty) => {
    if (convertToPretty) {
        return toString(dateObj, convertTime);
    }

    return dateObj;
};


/**
 *@description Convert date to string depending on options.
 * @param {*} dateObj date to convert. 
 * @param {object} options options to apply to the conversion.
 * coverTime: indicates if time portion will be included in the result.
 * convertToPretty: indicates if the date will have the format MMM-dd-yyyy.
 * fromExcel: indicate if the dateObj comes from excel.
 * defaultMinDate: if dateObj is not a Date object and is not  string date and is not an excel date, indicates if the minimum date will be returned or null if it is false
 * @return {string} date converted to string
 */
const convert = (dateObj, options) => {
    const excelDateTimeValue = null;
    options = { ...{ convertTime: false, convertToPretty: false, fromExcel: false, defaultMinDate: true }, ...(options || {}) };

    //Check to see if already date
    if (dateObj instanceof Date) {
        return returnDate(dateObj, options.convertTime, options.convertToPretty);
    }

    if (!isNaN(dateObj)) {
        if (options.fromExcel) {
            //Check to see if Excel
            let date = new Date(Math.round((excelDateTimeValue - EXCEL_DAYS_DIFFERENCE) * 86400 * 1000));
            return returnDate(date, options.convertTime, options.convertToPretty);
        } else {
            //Check to see if Epoch
            let date = new Date(0);
            date.setUTCSeconds(dateObj);
            return returnDate(date, options.convertTime, options.convertToPretty);
        }
    }

    if (typeof dateObj === 'string') {
        //Check to see ISO (date time)
        if (dateObj.includes(' ') || dateObj.includes('T')) {
            let isoDate = dateObj;
            if (!options.convertTime) {
                isoDate = isoDate.substring(0, Math.max(isoDate.indexOf(' '), isoDate.indexOf('T')));
            }

            let date = parseISO(isoDate);
            return returnDate(date, options.convertTime, options.convertToPretty);
        }

        //Attempt to convert string to date
        let date = new Date(dateObj);
        return returnDate(date, options.convertTime, options.convertToPretty);
    }

    return options.defaultMinDate ? MIN_DATE : null;
};

const DuDateUtilities = {
    ToString: toString,
    IsValidDate: isValidDate,
    ToPrettyDate: (value) => {
        if (!value) {
            return null;
        }

        if (value instanceof Date) {
            return toString(value);
        }

        if (typeof value === 'string') {
            if (value.indexOf('-') === 3) {
                //Pretty Format
                if (value.indexOf(' ')) {
                    //Remove time component
                    return value.split(' ')[0];
                }
                return value;
            }

            if (value.indexOf('-') === 4) {
                //ISO Format
                return formatDateFromIso(value);
            }
        }

        return null;
    },
    ToPrettyDateTime: (value) => {
        if (!value) {
            return null;
        }

        if (value instanceof Date) {
            return toString(value, true);
        }

        if (typeof value === 'string') {
            if (value.indexOf('-') === 3) {
                //Pretty Format
                return value;
            }

            if (value.indexOf('-') === 4) {
                //ISO Format
                return formatDateTimeFromIso(value);
            }
        }

        return null;
    },
    FormatDateFromIso: formatDateFromIso,
    FormatDateTimeFromIso: formatDateTimeFromIso,
    ToIsoDate: function (date, addNullTime = true, failPlaceholder = 'N/A', addBeforeMidnight = false) {
        try {
            var isoDate = format(date, ISO_DATE_FORMAT);

            return isoDate + (addNullTime ? NULL_TIME : addBeforeMidnight ? BEFORE_MIDNIGHT_TIME : '');
        } catch (error) {
            console.error('Got an incorrect date to be able to ISO format');

            return failPlaceholder;
        }
    },
    GetWeekNumber: getWeekNumber,
    GetPoolWeek: function (date) {
        return date.getFullYear().toString().substring(2) + ('0' + getWeekNumber(date).toString()).slice(-2);
    },
    TimeSpanToString: function (start, end) {
        var difference = end - start;

        if (difference > 1000) {
            difference /= 1000;

            if (difference > 60) {
                difference /= 60;

                if (difference > 60) {
                    return `${(difference / 60).toFixed(2)} h`;
                }

                return `${difference.toFixed(2)} min`;
            }

            return `${difference.toFixed(2)} s`;
        }

        return `${difference} ms`;
    },
    getDateFromExcelTimeStamp: function (excelDateTimeValue) {
        if (!excelDateTimeValue) {
            return '';
        }

        const jsDateTimeStamp = new Date(Math.round((excelDateTimeValue - EXCEL_DAYS_DIFFERENCE) * 86400 * 1000));
        return this.ToIsoDate(jsDateTimeStamp, '').split('T')[0];
    },
    Convert: convert,
    EXCEL_DAYS_DIFFERENCE,
    MIN_DATE,
    BEFORE_MIDNIGHT_TIME
};

export default DuDateUtilities;
